<template>
    <div @scroll="scrollIt" style="font-size:.38rem;background: rgb(247,247,247);height:100%;overflow:auto">
        <div class="top-point">
            <h2>
                {{user.integral}}
            </h2>
        </div>
        <div class="exchange-record">
            <van-button style="flex:1;" type="default">{{user.xz_identity}}</van-button>
            <van-button style="flex:1;" type="default">{{user.created_at?user.created_at.split(' ')[0]:''}}</van-button>
        </div>
        <div>
            <h5 style="padding:.2rem .3rem;background: white">墨豆明细</h5>
        </div>
        <div v-if="details.length!==0">
            <div v-for="item in details" class="point-detail">
                <div class="detail">
                    <span>{{item.integral}}</span>
                    <span class="text-resolve1" style="width:50%">标题：{{item.name}}</span>
                </div>
                <div class="detail">
                    <span style="color:rgb(168,168,168)">{{item.type_text}}</span>
                    <span>{{item.created_at.split(' ')[0]}}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <van-empty description="暂无记录" />
        </div>
        <div style="text-align: center;height:.7rem;">
            <van-loading v-show="loading"/>
        </div>
    </div>
</template>

<script>
    import Notify from "vant/lib/notify";

    export default {
        name: "point",
        data() {
            return {
                user: {},
                details: [],
                page: 0,
                TYPE: {
                    1: '分享的文章被人阅读',
                    2: '兑换商品',
                    3: '取消商品兑换',
                    4: '第一次分享文章',
                },
                loading: true
            }
        },
        created() {
            document.title = '墨豆明细';
            this.getUser();
            this.getDetails(++this.page);
        },
        methods: {
            scrollIt(e) {
                let e_t = e.target.scrollTop;
                let e_h = e.target.scrollHeight;
                let e_c = e.target.clientHeight;
                if (e_t + e_c >= e_h) {
                    this.loading = true;
                    this.getDetails(++this.page)
                }
            },
            getUser() {
                this.$axios.get('miniprogram/integralUser').then((res) => {
                    if (res.data.code === '0000') {
                        this.user = res.data.content.user
                    }
                })
            },
            getDetails() {
                this.$axios.get('miniprogram/integralHistory?page=' + this.page + '&size=10').then((res) => {
                    if (res.data.code === '0000') {
                        this.loading = false;
                        if (Math.ceil(res.data.content.count / 10) > this.page) {
                            // this.d(() => {
                                this.details = [...res.data.content.data, ...this.details];
                            // })
                            this.loading = false
                        } else if (Math.ceil(res.data.content.count / 10) === this.page) {
                            if (res.data.content.data.length > (this.details.length % 10)) {

                                // this.d(() => {
                                    let temp_items = res.data.content.data.splice(this.details.length);
                                    this.details = [...temp_items, ...this.details];
                                    --this.page
                                // })
                            } else {
                                // this.d(() => {
                                    Notify({
                                        message: '没有更多了',
                                        color: '#ad0000',
                                        background: '#ffe1e1',
                                        duration:1000
                                    });
                                    --this.page
                                // });
                            }
                            this.loading = false
                        }
                    } else {
                        // this.d(() => {
                            Notify({
                                message: '拉取更多失败',
                                color: '#ad0000',
                                background: '#ffe1e1',
                            });
                            --this.page
                        // });
                        this.loading = false
                    }
                })
            },
            d(c) {
                let st = setTimeout(() => {
                    clearTimeout(st);
                    st = null;
                    c ? c() : '';
                    this.loading = false
                }, 1000)
            }
        }
    }
</script>

<style scoped>
    .top-point {
        background: white;
        height: 30vh;
        line-height: 30vh;
        text-align: center;
    }

    .exchange-record {
        height: 10 h;
        display: flex;
        justify-content: space-evenly;
    }

    .point-detail {
        margin: .2rem .3rem;
        padding: .2rem .3rem;
        background: white;
        border-radius: 10px;
        font-size: .33rem;
    }

    .detail {
        display: flex;
        justify-content: space-between;
    }
    .text-resolve1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
